import React from "react";
import styled from "styled-components";
import { useTheme } from "@mui/material/styles";
import { signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { auth, firestore } from "../coreApp/firebase";
import { useNavigate } from "react-router-dom";
import { doc, getDoc, setDoc } from "firebase/firestore";

const Button = styled.button`
  padding: 20px 30px;
  font-size: 24px;
  border: none;
  border-radius: 4px;
  background-color: lightblue; /* Set to light blue */
  color: black; /* Set text color to black */
  cursor: pointer;
  margin-top: 10px;
  width: 200px;
  height: 60px;

  /* Removed hover styles */
`;

function GoogleSignUpButton() {
  const navigate = useNavigate();
  const theme = useTheme();

  const handleGoogleSignup = async () => {
    const provider = new GoogleAuthProvider();

    try {
      const userCredential = await signInWithPopup(auth, provider);
      const user = userCredential.user;

      if (user) {
        const userDocRef = doc(firestore, "users", user.uid);
        const userDocSnap = await getDoc(userDocRef);

        if (!userDocSnap.exists()) {
          // If the user does not exist in Firestore, initialize the user structure with characterCount
          await setDoc(
            userDocRef,
            {
              isActive: false,
              characterCount: 0,
              displayName: user.displayName,
              creationTime: user.metadata.creationTime,
            },
            { merge: true }
          );
        }

        // Navigate the user to the app whether they are new or existing
        navigate("/app");
      } else {
        alert("Google Signup failed");
      }
    } catch (error) {
      alert("Error during Google Signup: " + error.message);
    }
  };

  return (
    <Button onClick={handleGoogleSignup} theme={theme}>
      Try For Free
    </Button>
  );
}

export default GoogleSignUpButton;
