import React from "react";
import {
  Box,
  Card,
  CardContent,
  Typography,
  Avatar,
  Grid,
} from "@mui/material";
import { StarRate } from "@mui/icons-material";
import GoogleSignUpButton from "./GoogleSignUpButton"; // Import the GoogleSignUpButton component

const testimonials = [
  {
    name: "Alice",
    feedback: "This AI app transformed my manuscript into a perfect audiobook.",
    occupation: "Author",
    avatar: "https://i.pravatar.cc/150?img=1",
  },
  {
    name: "Bob",
    feedback: "Incredible! The audio quality is superb. Highly recommended.",
    occupation: "Author",
    avatar: "https://i.pravatar.cc/150?img=8",
  },
  {
    name: "Charlie",
    feedback:
      "A game-changer for writers who need their books read fast and cheap. Very easy to use",
    occupation: "Author",
    avatar: "https://i.pravatar.cc/150?img=3",
  },
];

const Testimonials = () => {
  return (
    <Box padding={5} bgcolor="#f0f0f0">
      <Typography
        variant="h4"
        gutterBottom
        textAlign="center"
        style={{ color: "black" }}
      >
        What Our Users Say
      </Typography>

      <Grid container spacing={3} justifyContent="center">
        {testimonials.map((testimonial, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card elevation={3}>
              <CardContent>
                <Box display="flex" alignItems="center" marginBottom={2}>
                  <Avatar src={testimonial.avatar} alt={testimonial.name} />
                  <Box marginLeft={2}>
                    <Typography variant="subtitle1">
                      {testimonial.name}
                    </Typography>
                    <Typography variant="subtitle2" color="textSecondary">
                      {testimonial.occupation}
                    </Typography>
                  </Box>
                </Box>

                <Typography variant="body2" style={{ fontStyle: "italic" }}>
                  "{testimonial.feedback}"
                </Typography>

                <Box display="flex" alignItems="center" marginTop={2}>
                  <StarRate color="primary" />
                  <StarRate color="primary" />
                  <StarRate color="primary" />
                  <StarRate color="primary" />
                  <StarRate color="primary" />
                </Box>
              </CardContent>
            </Card>
          </Grid>
        ))}
        <Grid item xs={12} style={{ textAlign: "center" }}>
          <GoogleSignUpButton />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Testimonials;
