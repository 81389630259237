import { styled } from "@mui/material/styles";
import backgroundImage from "../Assets/10.png"; // Import the background image

export const MainContainer = styled("div")(({ theme }) => ({
  backgroundImage: `url(${backgroundImage})`,
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
  backgroundColor: theme.palette.background.default,
  minHeight: "100vh",
  display: "flex",
  flexDirection: "column",
}));

export const ContentContainer = styled("main")(({ theme }) => ({
  flexGrow: 1,
  paddingTop: "40px",
  paddingBottom: "40px",
  paddingLeft: "5%", // Added left padding
  paddingRight: "5%", // Added right padding
  [theme.breakpoints.down("sm")]: {
    paddingTop: "20px",
    paddingBottom: "20px",
    paddingLeft: "3%", // Adjusted left padding for smaller screens
    paddingRight: "3%", // Adjusted right padding for smaller screens
  },
}));

export const SectionBox = styled("div")(({ theme }) => ({
  marginBottom: "40px",
  [theme.breakpoints.down("sm")]: {
    marginBottom: "20px",
  },
}));
