import React from "react";
import { AppBar, Toolbar, Typography, Button, useTheme } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

function Header() {
  const theme = useTheme();

  return (
    <AppBar
      position="static"
      sx={{
        backgroundColor: theme.palette.primary.main,
        padding: "20px 30px",
      }}
    >
      <Toolbar style={{ justifyContent: "space-between", padding: 0 }}>
        <Typography
          variant="h6"
          sx={{
            fontWeight: 500,
            fontSize: "calc(10px + 2vmin)",
            color: "#FFFFFF", // Set color to white
          }}
        >
          Auto Audiobooks
        </Typography>
        {/* <div style={{ display: "flex", gap: "15px" }}>
          <Button
            component={RouterLink}
            to="/signup"
            color="inherit"
            sx={{
              fontSize: "1.2rem",
              padding: "5px 5px",
            }}
          >
            Signup
          </Button>
          <Button
            component={RouterLink}
            to="/login"
            color="inherit"
            sx={{
              fontSize: "1.2rem",
              padding: "5px 20px",
            }}
          >
            Login
          </Button>
        </div> */}
      </Toolbar>
    </AppBar>
  );
}

export default Header;
