import React from "react";
import { Container } from "@mui/material";
import Header from "./Header";
import LogoDisplay from "./LogoDisplay";
import IntroSection from "./IntroSection";
import Testimonials from "./Testimonials"; // New import here
import Footer from "./Footer";
import GoogleSignUpButton from "./GoogleSignUpButton";
import { MainContainer, ContentContainer, SectionBox } from "./landingStyles";
import backgroundImage from "../Assets/13.png";

function LandingPage() {
  return (
    <MainContainer backgroundImage={backgroundImage} className="fade-in">
      <div className="fade-in">
        <Header />
      </div>

      <Container maxWidth="md" component={ContentContainer}>
        <div className="fade-in">
          <SectionBox>
            <IntroSection />
            <GoogleSignUpButton />
          </SectionBox>
        </div>
      </Container>

      <div className="fade-in" style={{ padding: "20px" }}>
        <LogoDisplay />
      </div>

      <div className="fade-in">
        <Testimonials /> {/* New Testimonials section here */}
      </div>

      <div className="fade-in">
        <Footer />
      </div>
    </MainContainer>
  );
}

export default LandingPage;
